// 全局less文件
// https://piccalil.li/blog/a-modern-css-reset/
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  font-size: 16px;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
  list-style: none;
  padding: 0;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.6;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

* {
  box-sizing: border-box;
}

:root {
  --background: rgb(252 252 252);
  --a1: rgba(37, 37, 37, 0.05);
  --a2: rgba(0 0 0 / 0.1);
  --foreground: rgb(28 28 29);
  --shadow: 0px 6px 20px rgb(0 0 0 / 20%);

  --unit: 8px;
}

html[data-theme-dark]:root {
  --background: rgb(28 28 29);
  --a1: rgb(53 53 54);
  --a2: rgba(255 255 255 / 0.1);
  --foreground: rgba(252 252 252 / 0.9);
  --shadow: rgb(0 0 0 / 50%) 0px 16px 70px;
}

html {
  background: var(--background);
  color: var(--foreground);
}

kbd {
  font-family: monospace;
  background: var(--a2);
  padding: calc(var(--unit) * 0.5);
  border-radius: calc(var(--unit) * 0.5);
}
