// 全局less文件
html,
body,
:global(#root) {
  height: 100%;
}

:global(.ant-layout) {
  min-height: 100%;
}

:global(.no-wrap) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

canvas {
  display: block;
}

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// temp fix for https://github.com/ant-design/ant-design/commit/a1fafb5b727b62cb0be29ce6e9eca8f579d4f8b7
:global {

  .ant-layout.ant-layout-has-sider>.ant-layout,
  .ant-layout.ant-layout-has-sider>.ant-layout-content {
    overflow-x: auto !important;
  }

  .ant-spin-container {
    overflow: visible !important;
  }

  .ant-btn {
    transition: none;
  }

  .global-spin {
    width: 100%;
    margin: 40px 0 !important;
  }

  // .ant-modal {
  //   width: 600px !important;
  // }
  .ant-modal-close-x {
    // width: 100px !important;
    height: 59px !important;
    line-height: 59px !important;
  }

  .ant-table-thead>tr>th {
    white-space: nowrap;
  }

  .ant-table td {
    white-space: nowrap;
  }

  .disable-with-white-color {
    .ant-input-disabled {
      background-color: #fff;
      opacity: 1;
      cursor: default;
      color: rgba(0, 0, 0, 0.65);
      border: none;

      &+.ant-calendar-picker-icon {
        display: none;
      }

      .ant-calendar-picker-icon {
        display: none;
      }

      .ant-calendar-range-picker-input {
        cursor: default;
      }
    }

    .ant-input-disabled:hover {
      border: none;
    }
  }

  .ant-cascader-menus {
    .ant-cascader-menu {
      height: 300px;
      min-width: 180px;
    }
  }
}

.formSearchStyle{
  :global {
    .ant-form-item {
      margin-bottom: 6px;
    }
    .ant-form-item-control-wrapper {
      flex: 1;
    }
    .ant-calendar-picker {
      width: 100% !important;
    }
  }
  .searchButtons {
    white-space: nowrap;
    margin-bottom: 24px;
    float: right;
    margin-right: 20px;
  }
  .leftOperateButtons{
    margin-bottom: 10px;
    display: block;
  }
  .rightOperateButtons{
    white-space: nowrap;
    float: right;
    margin-right: 20px;
    margin-bottom: 10px;
  }
}
.buttonSpace{
  margin-right: 15px;
}

.modalOneColumn {
  :global {
    .ant-form-item {
      margin-bottom: 6px !important;
    }
  }
}

.modalTwoColumn {
  display: flex;
  padding-top: 10px;
  :global {
    .ant-form-item {
      margin-bottom: 6px !important;
      display: flex;
    }
    .ant-form-item-control-wrapper {
      flex: 1;
    }
    .ant-calendar-picker {
      width: 100% !important;
    }
    .ant-form {
      width: 360px;
    }
  }
}

.modalTwoColumnWithExtraContent {
  display: flex;
  padding-top: 10px;
  :global {
    .ant-form-item {
      margin-bottom: 6px !important;
      display: flex;
    }
    .ant-form-item-control-wrapper {
      flex: 1;
    }
    .ant-calendar-picker {
      width: 100% !important;
    }
    .ant-form {
      width: 460px;
    }
  }
}

.formItemCenter {
  :global {
    .ant-form-item-label label {
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
  }
}